@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Khula:wght@300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Khula:wght@300;400;600;700;800&display=swap');  
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Khula:wght@300;400;600;700;800&family=Ruda:wght@400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Khula:wght@300;400;600;700;800&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Ruda:wght@400..900&display=swap');




.no-scrollbar::-webkit-scrollbar{
    display: none;
};

.no-scrollbar{
    -ms-overflow-style: none;
    scrollbar-width: none;
}


.ruda{
  font-family: 'Ruda';
};
.khula{
  font-family: 'Khula';
}

.montserat{
  font-family: 'Montserrat';
};

.figtree{
  font-family: 'Figtree';
};


body {
  margin: 0;
  background: white;
  font-family: '';
  -ms-overflow-style: none;
  scrollbar-width: none; 
};
